<!--
 * @Author: jjskay 404784102@qq.com
 * @Date: 2022-08-15 10:29:22
 * @LastEditors: jjskay 404784102@qq.com
 * @LastEditTime: 2022-08-15 10:29:30
 * @FilePath: /iot-product-tool/src/pages/videoCourse.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="page-not-found">
        <p>加载中...</p>
    </div>
</template>

<script>
export default {
    name: "VideoCourse",
    data() {
        return {
            meta: {
                title: '安装教程'
            }
        }
    },
    created() {
        const vm = this
        // 不同的安装设备类型
        // 1:750W增氧机 2:370W增氧机
        // 3: 1.5KW增氧机
        const { type } = vm.$route.query
        if (Number(type) < 3 && Number(type) > 0) {
            window.location.href = 'https://iot.tool.yudada.com/webview/installationVideo.html?type=1'
        }

        if (Number(type) == 3) {
            window.location.href = 'https://iot.tool.yudada.com/webview/installationVideo.html?type=3'
        }

        if (Number(type) == 4) {
            window.location.href = 'https://iot.tool.yudada.com/webview/installationVideo.html?type=4'
        }
    },
    methods: {}
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";

.page-not-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 40px;
    font-weight: bold;
    flex-direction: column;
    padding-bottom: 100px;

    p {
        text-align: center;
    }
}
</style>


